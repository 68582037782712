<template>
  <b-button
    :variant="variant"
    :size="size"
    :class="classes"
    @click="handleClick"
  >
    {{ label }}
  </b-button>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const handleClick = () => {
      axios({
        url: props.href,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${Date.now()}.${props.format}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    return {
      handleClick,
    }
  },
}
</script>
